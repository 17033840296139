import { AesCrypter } from "./utils/aesCrypter";
import moment from "moment";

export const getMockQrCode = (atmId) => {
  const mock = {
    AtmID: atmId,
    AtmLocation: "TASHKENT, SERGELI OBI-HAYOT",
    DateTime: "20231502 08:02:34",
    CashDispenserStatus: 20,
    MaxNotesToDispense: 60,
    Cass: [
      {
        denomination: 50,
        currency: "BEE",
        count: 934,
        min_count: 20,
        exponent: 0,
      },
    ],
  };

  const encryptedMock = AesCrypter.encrypt(qrCodeMockKey, JSON.stringify(mock));

  return {
    AtmID: atmId,
    Data: encryptedMock,
  };
};

export const qrCodeMockKey = "87A76894D397E38A88F640270988E0F4";
