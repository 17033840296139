import { useRef, useState } from "react";
import { useEffect } from "react";
import NavBar from "./components/Navbar";
import {
  Container,
  Modal,
  Button,
  Row,
  Col,
  Form,
  Badge,
  Alert,
  ToggleButton,
  ButtonGroup,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.scss";
import "./otp.scss";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import HomePage from "./pages/home/home";
import TransactionsPage from "./pages/transactions/transactions";
import { changeCurrentState, setTestAtmId } from "./appSlice";
import { useAppDispatch, useAppSelector } from "./hooks";
import { APPSTATE } from "./enums/appState";
import { DebugMode, LocalStorageItem, RadioValue } from "./enums/common";
import { Settings } from "./types/types";
import { getDebugMode, getHbFlow } from "./utils/common";
import { QRPAY_SIMULATOR_URL, TestTerminalID } from "./constants";
import { useTranslation } from "react-i18next";

const App = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const currentState = useAppSelector((state) => state.app.currentState);
  const testAtmId = useAppSelector((state) => state.app.testAtmId);
  // const serverAddress = JSON.parse(
  //   localStorage.getItem("serverAddress") ?? JSON.stringify(QRPAY_SIMULATOR_URL)
  // );
  const serverAddress = QRPAY_SIMULATOR_URL;

  const [showServerModal, setShowServerModal] = useState<boolean>(false);
  const [debugMode, setDebugMode] = useState<DebugMode>(DebugMode.Off);
  const [hbFlow, seHbFlow] = useState<RadioValue>(RadioValue.Off);

  const [state, setState] = useState<Settings>({
    serverAddress: serverAddress,
  });

  useEffect(() => {
    const testAtmId = JSON.parse(
      localStorage.getItem("testAtmId") ?? JSON.stringify(TestTerminalID)
    );
    console.log("saras setAtmId", setTestAtmId);
    dispatch(setTestAtmId(testAtmId));

    setDebugMode(getDebugMode());
    seHbFlow(getHbFlow());

    window.addEventListener(LocalStorageItem.DebugMode, () => {
      setDebugMode(getDebugMode());
    });

    window.addEventListener(LocalStorageItem.HbFlow, () => {
      seHbFlow(getHbFlow());
    });
  }, []);

  const closeServerChangeModal = () => {
    setShowServerModal(false);
  };

  const handleChangeServerClick = () => {
    setShowServerModal((prev) => !prev);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // const formData = new FormData(e.target);
    // const formDataObj = Object.fromEntries(formData.entries());
    // console.log("saras formDataObj", formDataObj);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    if (name === "serverAddress") {
      localStorage.setItem("serverAddress", JSON.stringify(value));
    }

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeTestAtmId = (e: any) => {
    const { name, value } = e.target;

    if (name === "testAtmId") {
      localStorage.setItem("testAtmId", JSON.stringify(value));
    }

    dispatch(setTestAtmId(value));
  };

  const debugRadios = [
    { name: "Off", value: DebugMode.Off },
    { name: "On", value: DebugMode.On },
  ];

  const hbModeRadios = [
    { name: "Off", value: RadioValue.Off },
    { name: "On", value: RadioValue.On },
  ];

  return (
    <Router>
      <div className="App">
        <NavBar onChangeServerClick={handleChangeServerClick} />
        {hbFlow === RadioValue.On && (
          <Container>
            <Row className="text-center">
              <p>HB FLOW</p>
            </Row>
          </Container>
        )}
        <Routes>
          <Route path="/transactions" element={<TransactionsPage />} />
          <Route path="/" element={<HomePage />} />
        </Routes>
        <Modal show={showServerModal} onHide={closeServerChangeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{t("Settings")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit} id="settingsForm">
              <Form.Group className="mb-1">
                <Form.Label>
                  {t("Server")}: {state.serverAddress}
                </Form.Label>
              </Form.Group>
              <Form.Group className="mb-3">
                {/* <Form.Label>Server:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Server address"
                  name="serverAddress"
                  value={state.serverAddress}
                  onChange={handleChange}
                /> */}
                <Form.Label>Test Terminal Id:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Test terminal Id"
                  name="testAtmId"
                  value={testAtmId}
                  onChange={handleChangeTestAtmId}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>HB flow: &nbsp;</Form.Label>
                <ButtonGroup>
                  {hbModeRadios.map((radio, idx) => (
                    <ToggleButton
                      key={idx}
                      id={`hbradio-${idx}`}
                      type="radio"
                      variant={idx % 2 ? "outline-success" : "outline-danger"}
                      name="hbradio"
                      value={radio.value}
                      checked={hbFlow === radio.value}
                      onChange={(e) => {
                        localStorage.setItem(
                          LocalStorageItem.HbFlow,
                          JSON.stringify(e.currentTarget.value)
                        );
                        window.dispatchEvent(
                          new Event(LocalStorageItem.HbFlow)
                        );
                      }}
                    >
                      {t(radio.name)}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Test mode: &nbsp;</Form.Label>
                <ButtonGroup>
                  {debugRadios.map((radio, idx) => (
                    <ToggleButton
                      key={idx}
                      id={`radio-${idx}`}
                      type="radio"
                      variant={idx % 2 ? "outline-success" : "outline-danger"}
                      name="radio"
                      value={radio.value}
                      checked={debugMode === radio.value}
                      onChange={(e) => {
                        localStorage.setItem(
                          LocalStorageItem.DebugMode,
                          JSON.stringify(e.currentTarget.value)
                        );
                        window.dispatchEvent(
                          new Event(LocalStorageItem.DebugMode)
                        );
                      }}
                    >
                      {t(radio.name)}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeServerChangeModal}>
              {t("Close")}
            </Button>
            {/* <Button variant="primary" type="submit" form="settingsForm">
              Save
            </Button> */}
          </Modal.Footer>
        </Modal>
      </div>
    </Router>
  );
};

export default App;
