import axios from "axios";
import { bankSimulatorInstance, atmDirectApiInstance } from "./apiInstance";
import moment from "moment";
import {
  GetKeyResult,
  SetOTPResult,
  WithdrawalResult,
  StartTransactionResult,
  BankTransaction,
  BadResponseResult,
  BankTransactionWithdrawalResult,
  Account,
  ATMStatus,
  AdmissionDetails,
} from "../types/apiTypes";
import { TransactionType } from "../enums/transactions";
import { instanceOfBadResponseResult } from "../utils/common";
import { ATMCommand } from "../enums/atmCommands";
import { enterOTPTimeout } from "../constants";

class bankSimulatorAPI {
  static async startTransaction(
    atmId: string,
    transactionTypeId: TransactionType
  ) {
    const body = {
      AtmID: atmId,
    };

    const { data } = await bankSimulatorInstance.get<StartTransactionResult>(
      `/bank/startTransaction?atmId=${atmId}&transactionTypeId=${transactionTypeId}`
    );

    if (instanceOfBadResponseResult(data)) {
      throw new Error(data.ErrorDescription);
    }

    return data;
  }
  static async getKey(atmId: string) {
    // try {
    const body = {
      AtmID: atmId,
      DateTime: moment().format("YYYYDDMM HH:mm:ss"), //"20212505 13:58:15",
    };

    const { data } = await bankSimulatorInstance.post<GetKeyResult>(
      `/bank/getkey`,
      body
    );

    console.log(JSON.stringify(data, null, 4));

    if (instanceOfBadResponseResult(data) && data.ResultCode !== "0") {
      throw new Error(JSON.stringify(data));
    }

    return data;
    // } catch (error) {
    //   if (axios.isAxiosError(error)) {
    //     console.log("error message: ", error.message);
    //     // 👇️ error: AxiosError<any, any>
    //     throw new Error(error.message);
    //     // return error.message;
    //   } else {
    //     console.log("unexpected error: ", error);
    //     throw new Error("An unexpected error occurred");
    //   }
    // }
  }

  static async setOTP(
    atmId: string,
    transactionId: string,
    transactionDateTime: string,
    otpCode: number,
    account: Account,
    language: string
  ) {
    const body = {
      AtmID: atmId,
      AtmLocation: "г.Ташкент, уг.А.Кадыри, 2",
      Amount: 50,
      Currency: "UZS",
      TransactionID: transactionId,
      TransactionDateTime:
        moment(transactionDateTime).format("YYYYDDMM HH:mm:ss"),
      OTP: otpCode,
      nextRequestTimeout: enterOTPTimeout / 1000, //60,
      UserData: {
        Id: account.accountId,
        Name: account.name,
        DeviceID: window.navigator.userAgent,
        IpAddress: account.ip,
        PhoneNumber: account.phoneNumber,
        Language: language, // "English",
      },
    };

    const { data } = await bankSimulatorInstance.post<SetOTPResult>(
      `/bank/setotp`,
      body
    );

    console.log(JSON.stringify(data, null, 4));

    if (instanceOfBadResponseResult(data) && data.ResultCode !== "0") {
      throw new Error(JSON.stringify(data));
    }

    return data;
  }

  static async withdrawal(
    atmId: string,
    amount: number,
    currency: string,
    otpCode: string,
    transactionId: string,
    accountName: string
  ) {
    // try {
    const body = {
      AtmID: atmId,
      WriteoffAccount: accountName,
      WithdrawalAmount: amount, // 5,
      WithdrawalCurrency: currency, // "BEE",
      TransactionID: transactionId, // "1111111121",
      TransactionType: 1,
      TransactionDateTime: moment().format("YYYYDDMM HH:mm:ss"), //"20212505 13:58:15",
      ApprovalCode: otpCode, // "9945",
      ReceiptData: [{}, {}],
    };

    const { data } = await bankSimulatorInstance.post<WithdrawalResult>(
      `/bank/withdrawal`,
      body
    );

    console.log(JSON.stringify(data, null, 4));

    if (instanceOfBadResponseResult(data) && data.ResultCode !== "0") {
      throw new Error(JSON.stringify(data));
    }

    return data;
    // } catch (error) {
    //   if (axios.isAxiosError(error)) {
    //     console.log("error message: ", error.message);
    //     // 👇️ error: AxiosError<any, any>
    //     throw new Error(error.message);
    //     // return error.message;
    //   } else {
    //     console.log("unexpected error: ", error);
    //     throw new Error("An unexpected error occurred");
    //   }
    // }
  }

  static async accept(
    atmId: string,
    userAccount: string,
    otpCode: number,
    transactionId: string,
    admissionDetails: AdmissionDetails
  ) {
    const body = {
      AtmID: atmId,
      // UserAccount: userAccount,
      TransactionID: transactionId,
      TransactionType: TransactionType.CashIn,
      ApprovalCode: otpCode,
      AdmissionDetails: admissionDetails,
    };

    const { data } = await bankSimulatorInstance.post<any>(
      `bank/deposit/accept`,
      body
    );

    console.log(JSON.stringify(data, null, 4));

    if (instanceOfBadResponseResult(data) && data.ResultCode !== "0") {
      throw new Error(JSON.stringify(data));
    }

    return data;
  }

  static async cancel(atmId: string, transactionId: string) {
    const body = {
      AtmID: atmId,
      TransactionID: transactionId,
    };

    const { data } = await bankSimulatorInstance.post<any>(
      `bank/deposit/cancel`,
      body
    );

    console.log(JSON.stringify(data, null, 4));

    if (instanceOfBadResponseResult(data) && data.ResultCode !== "0") {
      throw new Error(JSON.stringify(data));
    }

    return data;
  }

  static async sendCommand(
    atmId: string,
    transactionId: string,
    command: ATMCommand
  ) {
    const body = {
      atmID: atmId,
      transactionID: transactionId,
      command: command,
    };

    const { data } = await bankSimulatorInstance.post<WithdrawalResult>(
      `/bank/deposit/command/send`,
      body
    );

    console.log(JSON.stringify(data, null, 4));

    if (instanceOfBadResponseResult(data) && data.ResultCode !== "0") {
      throw new Error(JSON.stringify(data));
    }

    return data;
  }

  static async withdrawalResult(atmId: string, transactionId: string) {
    const body = {
      AtmID: atmId,
      TransactionID: transactionId,
    };

    const { data } =
      await bankSimulatorInstance.post<BankTransactionWithdrawalResult>(
        `/bank/withdrawalresult`,
        body
      );

    if (instanceOfBadResponseResult(data) && data.ResultCode !== "0") {
      throw new Error(JSON.stringify(data));
    }

    return data;
  }

  static async setScanUserQrCode(
    atmId: string,
    account: Account,
    language: string
  ) {
    const body = {
      AtmID: atmId,
      AtmLocation: "г.Ташкент, уг.А.Кадыри, 2",
      Amount: 50,
      Currency: "UZS",
      TransactionID: "",
      TransactionDateTime: moment().format("YYYYDDMM HH:mm:ss"),
      OTP: "",
      UserData: {
        Id: account.accountId,
        Name: account.name,
        DeviceID: window.navigator.userAgent,
        IpAddress: account.ip,
        PhoneNumber: account.phoneNumber,
        Language: language, //"English",
      },
    };

    const { data } = await atmDirectApiInstance.post<any>(
      `/BS2Crypto_ATM/scanUserWallet`,
      body
    );

    console.log("saras setScanUserQrCode", JSON.stringify(data, null, 4));

    if (instanceOfBadResponseResult(data) && data.ResultCode !== "0") {
      throw new Error(JSON.stringify(data));
    }

    return data;
  }

  static async getTransactions(atmId?: string) {
    let { data } = await bankSimulatorInstance.get<Array<BankTransaction>>(
      `/bank/transactions`
    );

    if (instanceOfBadResponseResult(data) && data.ResultCode !== "0") {
      throw new Error(JSON.stringify(data));
    }

    // data = JSON.parse(data)
    data.map((m) => (m.responseJson = JSON.parse(String(m.responseJson))));
    data.map((m) => (m.requestJson = JSON.parse(String(m.requestJson))));

    return data;
  }

  static async getTransactionById(atmId: string, transactionId: string) {
    const params = {
      atmId: atmId,
      transactionId: transactionId,
    };
    let { data } = await bankSimulatorInstance.get<BankTransaction[]>(
      `/bank/transactions`,
      {
        params: params,
      }
    );

    if (instanceOfBadResponseResult(data) && data.ResultCode !== "0") {
      throw new Error(JSON.stringify(data));
    }

    if (data && data.length > 0) {
      data[0].responseJson = JSON.parse(String(data[0].responseJson));
      data[0].requestJson = JSON.parse(String(data[0].requestJson));
    }

    return data && data.length > 0 ? data[0] : null;
  }

  static async sendBankApprove(
    atmId: string,
    transactionId: string,
    otpCode: number
  ) {
    const body = {
      AtmID: atmId,
      TransactionID: transactionId,
      ApprovalCode: otpCode,
      TransactionDateTime: moment().format("YYYY-MM-DDTHH:mm:ss.sss[Z]"), //"2023-04-17T07:54:24.048Z",
      // ReceiptData: "string",
      // ReceiptFormat: "string",
      ReceiptValues: [
        { Key: "Fee", Value: "0.05" },
        { Key: "Terminal", Value: "BS/2 Bank" },
        { Key: "AccountNumber", Value: "LT123456789" },
        { Key: "Status", Value: "Success" },
      ],
    };

    let { data } = await bankSimulatorInstance.post<any>(
      `/bank/deposit/approve`,
      body
    );

    if (instanceOfBadResponseResult(data) && data.ResultCode !== "0") {
      throw new Error(JSON.stringify(data));
    }

    // data = JSON.parse(data);
    // data.map((m) => (m.responseJson = JSON.parse(String(m.responseJson))));

    return data;
  }

  static async sendBankRollback(atmId: string, transactionId: string) {
    const body = {
      AtmID: atmId,
      TransactionID: transactionId,
      ReceiptValues: [
        { Key: "Fee", Value: "0.05" },
        { Key: "Terminal", Value: "BS/2 Bank" },
        { Key: "AccountNumber", Value: "LT123456789" },
        { Key: "Status", Value: "Failed" },
      ],
    };

    let { data } = await bankSimulatorInstance.post<any>(
      `/bank/deposit/rollback`,
      body
    );

    if (instanceOfBadResponseResult(data) && data.ResultCode !== "0") {
      throw new Error(JSON.stringify(data));
    }

    // data = JSON.parse(data);
    // data.map((m) => (m.responseJson = JSON.parse(String(m.responseJson))));

    return data;
  }

  static async hb_withdrawal(
    atmId: string,
    amount: number,
    currency: string,
    otpCode: string,
    transactionId: string,
    stan: number,
    accountName: string
  ) {
    const body = {
      atmID: atmId,
      writeoffAccount: accountName,
      withdrawalAmount: amount,
      withdrawalCurrency: currency,
      stan: stan,
      rrn: 0,
      transactionType: 1,
      transactionDateTime: moment().format("YYYYDDMM HH:mm:ss"), //"20212505 13:58:15",
    };

    const { data } = await bankSimulatorInstance.post<WithdrawalResult>(
      `/bank/hb_withdrawal`,
      body
    );

    console.log("saras hb_withdrawal", JSON.stringify(data, null, 4));

    if (instanceOfBadResponseResult(data) && data.ResultCode !== "0") {
      throw new Error(JSON.stringify(data));
    }

    return data;
  }

  static async hb_incustomerservice(atmId: string, stan: number) {
    const body = {
      atmID: atmId,
      dateTime: moment().format("YYYYDDMM HH:mm:ss"),
      stan: stan,
      nextRequestTimeout: enterOTPTimeout / 1000, //60,
      language: "string",
      customerInfo: {
        customerFirstName: "FirstName",
        customerSecondName: "LastName",
      },
    };

    const { data } = await bankSimulatorInstance.post<any>(
      `/bank/hb_incustomerservice`,
      body
    );

    console.log("saras hb_incustomerservice", JSON.stringify(data, null, 4));

    if (instanceOfBadResponseResult(data) && data.ResultCode !== "0") {
      throw new Error(JSON.stringify(data));
    }

    return data;
  }

  static async hb_withdrawalresult(atmId: string, stan: number) {
    const body = {
      atmID: atmId,
      stan: stan,
      rrn: 0,
    };

    const { data } = await bankSimulatorInstance.post<any>(
      `/bank/hb_withdrawalresult`,
      body
    );

    console.log("saras hb_withdrawalresult", JSON.stringify(data, null, 4));

    if (instanceOfBadResponseResult(data) && data.ResultCode !== "0") {
      throw new Error(JSON.stringify(data));
    }

    return data;
  }

  static async getATMStatus(atmId: string) {
    const { data } = await bankSimulatorInstance.get<ATMStatus>(
      `/atm/deposit/status`
    );

    if (instanceOfBadResponseResult(data) && data.ResultCode !== "0") {
      throw new Error(JSON.stringify(data));
    }

    return data;
  }
}

export default bankSimulatorAPI;
