import { DebugMode, LocalStorageItem, RadioValue } from "../enums/common";
import { BadResponseResult } from "../types/apiTypes";

export const getDebugMode = (): DebugMode => {
  const debugMode = JSON.parse(
    localStorage.getItem(LocalStorageItem.DebugMode) ??
      JSON.stringify(DebugMode.Off)
  );

  return debugMode;
};

export const getHbFlow = (): RadioValue => {
  const hbFlow = JSON.parse(
    localStorage.getItem(LocalStorageItem.HbFlow) ??
      JSON.stringify(RadioValue.Off)
  );

  return hbFlow;
};
export const syntaxHighlight = (json: string): string => {
  json = json
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;");
  return json.replace(
    /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
    function (match) {
      var cls = "number";
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = "key";
        } else {
          cls = "string";
        }
      } else if (/true|false/.test(match)) {
        cls = "boolean";
      } else if (/null/.test(match)) {
        cls = "null";
      }
      return '<span class="' + cls + '">' + match + "</span>";
    }
  );
};

export const isJsonString = (str: string): boolean => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const instanceOfBadResponseResult = (
  object: any
): object is BadResponseResult => {
  return "ResultCode" in object;
};

export const maskOtp = (otp: number): string => {
  if (otp > 0) {
    const str = otp.toString();
    return str.substring(0, str.length - 2) + "**";
  }
  return otp ? otp.toString() : "";
};
