import React, { useEffect } from "react";
import { Row, Alert, Col } from "react-bootstrap";
import { Clock } from "react-bootstrap-icons";

const Loader = () => {
  return (
    <div className="d-flex justify-content-center pt-4 pb-4">
      <div className="spinner-border text-success" role="status"></div>
    </div>
  );
};

export default Loader;
