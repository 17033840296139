import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import bankSimulatorAPI from "./apis/bankSimulatorAPI";
import { TransactionStatus } from "./enums/transactions";
import type { RootState } from "./store";
import { BankTransaction } from "./types/apiTypes";

// Define a type for the slice state
interface transactionPageState {
  items: Array<BankTransaction> | null;
  reloadingItemsIds: Array<string>;
  loading: boolean;
  error: any;
}

// Define the initial state using that type
const initialState: transactionPageState = {
  items: null,
  reloadingItemsIds: [],
  loading: false,
  error: "",
};

export const fetchTransactions = createAsyncThunk(
  "transactions/fetchTransactions",
  async (_: void, { rejectWithValue }) => {
    try {
      const response = await bankSimulatorAPI.getTransactions();
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchGetWithdrawalResult = createAsyncThunk(
  "transactions/fetchGetWithdrawalResult",
  async (data: any, { rejectWithValue }) => {
    try {
      const { atmId, transactionId } = data;
      const response = await bankSimulatorAPI.withdrawalResult(
        atmId,
        transactionId
      );
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchTransactions.pending, (state, action) => {
        state.error = "";
        state.loading = true;
        state.items = null;
      })
      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(fetchTransactions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message + " " + (action.payload ?? "");
      })
      .addCase(fetchGetWithdrawalResult.pending, (state, { payload, meta }) => {
        state.reloadingItemsIds.push(meta.arg.transactionId);
      })
      .addCase(fetchGetWithdrawalResult.fulfilled, (state, action) => {
        const currentTran = state.items?.find(
          (m) => m.transactionId === action.payload.TransactionId
        );
        if (currentTran) {
          currentTran.status = action.payload.Status;
        }

        const index = state.reloadingItemsIds.indexOf(
          action.payload.TransactionId,
          0
        );
        if (index > -1) {
          state.reloadingItemsIds.splice(index, 1);
        }
      })
      .addCase(
        fetchGetWithdrawalResult.rejected,
        (state, { payload, meta }) => {
          const index = state.reloadingItemsIds.indexOf(
            meta.arg.transactionId,
            0
          );
          if (index > -1) {
            state.reloadingItemsIds.splice(index, 1);
          }
          // state.loading = false;
          // state.error = action.error.message + " " + (action.payload ?? "");
        }
      );
  },
});

export const selectTransactions = (state: RootState) =>
  state.transactions.items;

export const selectReloadingItemsIds = (state: RootState) =>
  state.transactions.reloadingItemsIds;
export default transactionsSlice.reducer;
