import React, { useEffect, useState } from "react";
import "./styles.scss";
import {
  Nav,
  Navbar,
  NavDropdown,
  Button,
  Container,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { QrCodeScan, Gear, ListCheck, Person } from "react-bootstrap-icons";
import { Link, useNavigate } from "react-router-dom";
import { UILanguages } from "../../constants";
import { UILanguage } from "../../types/types";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { changeCurrentState, setUILanguage } from "../../appSlice";
import ReactCountryFlag from "react-country-flag";
import { APPSTATE } from "../../enums/appState";
import i18n from "../../i18n";

// import { ReactComponent as Logo } from "./logo.svg";
const NavBar = ({ onChangeServerClick }: any) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // const [UILanguage, setUILanguage] = useState<UILanguage>(
  //   JSON.parse(
  //     localStorage.getItem("uiLanguage") ?? JSON.stringify(UILanguages[0])
  //   )
  // );

  // const changeLanguageHandler = (lang) => {
  //   i18n.changeLanguage(lang);

  //   let culture = DEFAULT_CULTURE;

  //   switch (lang) {
  //     case "ru":
  //       culture = "ru-ru";
  //       break;
  //     case "en":
  //       culture = "en-US";
  //       break;
  //     default:
  //       break;
  //   }

  //   setLang(lang);
  //   setCulture(culture);
  // };

  const UILanguage = useAppSelector((state) => state.app.uiLanguage);

  const handleChangeLanguage = (e: any, lang: UILanguage) => {
    console.log("handleChangeLanguage", lang);
    localStorage.setItem("uiLanguage", JSON.stringify(lang));
    i18n.changeLanguage(lang.Code.toLowerCase());
    dispatch(setUILanguage(lang));
    // setUILanguage(lang);
  };

  const handleTogglePerson = () => {
    navigate("/");
    dispatch(changeCurrentState(APPSTATE.Home));
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="dark"
      variant="dark"
      className="justify-content-between"
    >
      <Container>
        <Navbar.Text>
          <Button
            className="btn"
            type="button"
            variant="outline-success"
            onClick={handleTogglePerson}
          >
            <Person />
          </Button>
          <Link to="/transactions">
            <Button className="btn ms-2" variant="outline-success">
              <ListCheck />
            </Button>
          </Link>
        </Navbar.Text>
        <Navbar.Brand className="mx-auto">
          {/* <Logo width="30" height="30" className="d-inline-block align-top" /> */}
          <Link to="/" className="text-light text-decoration-none">
            QRPay
          </Link>
        </Navbar.Brand>
        {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav
            className="container-fluid justify-content-end"
            style={{ width: "100%" }}
          >
            <Nav.Link href="#features">About</Nav.Link>
            <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse> */}
        <Navbar.Text>
          <span className="btn">
            <DropdownButton
              title={<ReactCountryFlag countryCode={UILanguage.UICode} svg />}
              variant="outline-success"
            >
              {UILanguages.map((lang) => {
                return (
                  <Dropdown.Item onClick={(e) => handleChangeLanguage(e, lang)}>
                    <ReactCountryFlag countryCode={lang.UICode} svg />{" "}
                    <span className="text-dark">{lang.Name}</span>
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
          </span>
          <Button
            className="btn"
            type="button"
            variant="outline-success"
            onClick={onChangeServerClick}
          >
            <Gear />
          </Button>
        </Navbar.Text>
      </Container>
    </Navbar>
  );
};

export default NavBar;
