import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import { APPSTATE } from "./enums/appState";
import bankSimulatorAPI from "./apis/bankSimulatorAPI";
import { RequestStatus } from "./enums/requestStatus";
import {
  SetOTPResult,
  StartTransactionResult,
  BadResponseResult,
  Account,
} from "./types/apiTypes";
import { TransactionType } from "./enums/transactions";
import { bankAccounts, TestTerminalID, UILanguages } from "./constants";
import bankAPI from "./apis/bankAPI";
import {
  parseCashinAccepedAmount,
  parseCashinDepositedAmount,
  parseCashoutTransaction,
} from "./utils/transactions";
import { UILanguage } from "./types/types";

// Define a type for the slice state
interface QRPayAppState {
  uiLanguage: UILanguage;
  testAtmId: string;
  previousState: string;
  currentState: string;
  status: RequestStatus;
  atmEncryptKey: string;
  transactionInfo: StartTransactionResult | null;
  accounts: Array<Account>;
  currentAccount: Account | null;
  otpResult: SetOTPResult | null;
  withrawalResult: any;
  atmStatus: any | null;
  transactionStatus: any | null;
  depositAmount: {
    amount: number;
    currency: string;
    lastPolledCommand?: number | null;
  };
  error: any;
}

// Define the initial state using that type
const initialState: QRPayAppState = {
  testAtmId: TestTerminalID,
  uiLanguage: JSON.parse(
    localStorage.getItem("uiLanguage") ?? JSON.stringify(UILanguages[0])
  ),
  previousState: "",
  status: RequestStatus.Empty,
  currentState: APPSTATE.Home,
  transactionInfo: null,
  atmEncryptKey: "",
  otpResult: null,
  withrawalResult: null,
  accounts: bankAccounts,
  currentAccount: bankAccounts[0],
  atmStatus: null,
  transactionStatus: null,
  depositAmount: { amount: 0, currency: "", lastPolledCommand: null },
  error: "",
};

export const fetchStartTransaction = createAsyncThunk(
  "atm/startTransaction",
  async (data: any, { rejectWithValue }) => {
    try {
      const { atmId, transactionTypeId } = data;
      const response = await bankSimulatorAPI.startTransaction(
        atmId,
        transactionTypeId
      );
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const fetchATMKey = createAsyncThunk(
  "atm/getKey",
  async (atmId: string, { rejectWithValue }) => {
    try {
      const response = await bankSimulatorAPI.getKey(atmId);
      return response;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const sendSetOTP = createAsyncThunk(
  "atm/setOTP",
  async (data: any, { rejectWithValue }) => {
    try {
      const {
        atmId,
        transactionId,
        transactionDateTime,
        otpCode,
        account,
        language,
      } = data;
      const response = await bankSimulatorAPI.setOTP(
        atmId,
        transactionId,
        transactionDateTime,
        otpCode,
        account,
        language
      );
      return response;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const withdraw = createAsyncThunk(
  "atm/withdraw",
  async (data: any, { rejectWithValue }) => {
    try {
      const { atmId, amount, currency, otpCode, transactionId, account } = data;
      const response = await bankSimulatorAPI.withdrawal(
        atmId,
        amount,
        currency,
        otpCode,
        transactionId,
        account?.name
      );
      return response;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const accept = createAsyncThunk(
  "bank/accept",
  async (data: any, { rejectWithValue }) => {
    try {
      const { atmId, userAccount, otpCode, transactionId, admissionDetails } =
        data;
      const response = await bankSimulatorAPI.accept(
        atmId,
        userAccount,
        otpCode,
        transactionId,
        admissionDetails
      );
      return response;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const cancel = createAsyncThunk(
  "bank/cancel",
  async (data: any, { rejectWithValue }) => {
    try {
      const { atmId, transactionId } = data;
      const response = await bankSimulatorAPI.cancel(atmId, transactionId);
      return response;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const sendCommand = createAsyncThunk(
  "atm/sendCommand",
  async (data: any, { rejectWithValue }) => {
    try {
      const { atmId, transactionId, command } = data;
      const response = await bankSimulatorAPI.sendCommand(
        atmId,
        transactionId,
        command
      );
      return response;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const hb_withdraw = createAsyncThunk(
  "atm/hb_withdraw",
  async (data: any, { rejectWithValue }) => {
    try {
      const { atmId, amount, currency, otpCode, transactionId, stan, account } =
        data;
      const response = await bankSimulatorAPI.hb_withdrawal(
        atmId,
        amount,
        currency,
        otpCode,
        transactionId,
        stan,
        account?.name
      );
      return response;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const hb_withdrawalresult = createAsyncThunk(
  "atm/hb_withdrawalresult",
  async (data: any, { rejectWithValue }) => {
    try {
      const { atmId, stan } = data;
      const response = await bankSimulatorAPI.hb_withdrawalresult(atmId, stan);
      return response;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const hb_incustomerservice = createAsyncThunk(
  "atm/hb_incustomerservice",
  async (data: any, { rejectWithValue }) => {
    try {
      const { atmId, stan } = data;
      const response = await bankSimulatorAPI.hb_incustomerservice(atmId, stan);
      return response;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const scanCryptoWallet = createAsyncThunk(
  "atm/scanCryptoWallet",
  async (data: any, { rejectWithValue }) => {
    try {
      const { atmId, account, language } = data;
      const response = await bankSimulatorAPI.setScanUserQrCode(
        atmId,
        account,
        language
      );
      return response;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchATMStatus = createAsyncThunk(
  "atm/atmStatus",
  async (atmId: string, { rejectWithValue }) => {
    try {
      const response = await bankSimulatorAPI.getATMStatus(atmId);
      return response;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchTransaction = createAsyncThunk(
  "atm/fetchTransaction",
  async (data: any, { rejectWithValue }) => {
    try {
      const { atmId, transactionId } = data;
      const response = await bankSimulatorAPI.getTransactionById(
        atmId,
        transactionId
      );
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sendBankApprove = createAsyncThunk(
  "bank/approve",
  async (data: any, { rejectWithValue }) => {
    try {
      const { atmId, transactionId, transactionDateTime, otpCode } = data;
      const response = await bankSimulatorAPI.sendBankApprove(
        atmId,
        transactionId,
        otpCode
      );
      return response;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const sendBankRollback = createAsyncThunk(
  "bank/rollback",
  async (data: any, { rejectWithValue }) => {
    try {
      const { atmId, transactionId } = data;
      const response = await bankSimulatorAPI.sendBankRollback(
        atmId,
        transactionId
      );
      return response;
    } catch (err: any) {
      if (!err.response) {
        throw err;
      }
      return rejectWithValue(err.response.data);
    }
  }
);

export const appSlice = createSlice({
  name: "app",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    clearErrors: (state, action: PayloadAction<void>) => {
      state.error = "";
    },
    sendSetAccount: (state, action: PayloadAction<Account>) => {
      state.currentAccount = action.payload;
      appSlice.caseReducers.changeCurrentState(state, {
        type: "app/changeCurrentState",
        payload: APPSTATE.ScanCode,
      });
    },
    setDepositAmount: (state, action: PayloadAction<number>) => {
      state.depositAmount.amount = state.depositAmount?.amount + action.payload;
    },
    setTestAtmId: (state, action: PayloadAction<string>) => {
      state.testAtmId = action.payload;
    },
    setUILanguage: (state, action: PayloadAction<UILanguage>) => {
      state.uiLanguage = action.payload;
    },
    setTestAtmEncryptKey: (state, action: PayloadAction<string>) => {
      state.atmEncryptKey = action.payload;
    },
    changeCurrentState: (state, action: PayloadAction<string>) => {
      if (state.currentState !== action.payload) {
        state.previousState = state.currentState;
        state.currentState = action.payload;
      }

      if (action.payload === APPSTATE.Home) {
        state.transactionInfo = null;
        state.error = "";
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchStartTransaction.pending, (state, action) => {
        state.error = "";
        state.atmEncryptKey = "";
        state.atmStatus = null;
        state.transactionStatus = null;
        state.transactionInfo = null;
        state.status = RequestStatus.Loading;
      })
      .addCase(fetchStartTransaction.fulfilled, (state, action) => {
        state.status = RequestStatus.Succeeded;
        state.transactionInfo = action.payload;
      })
      .addCase(fetchStartTransaction.rejected, (state, action) => {
        state.status = RequestStatus.Failed;
        // state.error = action.error.message;
        state.error =
          action?.error?.message +
          " " +
          (action?.payload ? JSON.stringify(action?.payload) : "");
        state.previousState = state.currentState;
        state.currentState = APPSTATE.Error;
      })
      .addCase(fetchATMKey.pending, (state, action) => {
        state.error = "";
        state.status = RequestStatus.Loading;
      })
      .addCase(fetchATMKey.fulfilled, (state, action) => {
        state.status = RequestStatus.Succeeded;
        state.atmEncryptKey = action.payload.SecretCode; // state.posts.concat(action.payload);
      })
      .addCase(fetchATMKey.rejected, (state, action) => {
        state.status = RequestStatus.Failed;
        // state.error = action.error.message;
        state.error =
          action?.error?.message +
          " " +
          (action?.payload ? JSON.stringify(action?.payload) : "");
        state.previousState = state.currentState;
        state.currentState = APPSTATE.Error;
      })
      .addCase(sendSetOTP.pending, (state, action) => {
        state.otpResult = null;
        state.error = "";
        state.status = RequestStatus.Loading;
      })
      .addCase(sendSetOTP.fulfilled, (state, action) => {
        state.status = RequestStatus.Succeeded;
        state.otpResult = action.payload; // state.posts.concat(action.payload);

        state.previousState = state.currentState;
        state.currentState = APPSTATE.EnterOTP;
      })
      .addCase(sendSetOTP.rejected, (state, action) => {
        state.status = RequestStatus.Failed;
        // state.error = action.error.message;
        state.error =
          action?.error?.message +
          " " +
          (action?.payload ? JSON.stringify(action?.payload) : "");
        state.previousState = state.currentState;
        state.currentState = APPSTATE.Error;
      })
      .addCase(withdraw.pending, (state, action) => {
        state.error = "";
        state.previousState = state.currentState;
        state.currentState = APPSTATE.WaitingResult;
        state.status = RequestStatus.Loading;
      })
      .addCase(
        withdraw.fulfilled,
        (state, action: PayloadAction<SetOTPResult>) => {
          state.status = RequestStatus.Succeeded;
          state.withrawalResult = action.payload; // state.posts.concat(action.payload);

          if (action.payload && action.payload.ErrorCode) {
            state.error =
              "Result code: " +
              action.payload.ResultCode +
              ": " +
              action.payload.ErrorCode;
            state.currentState = APPSTATE.Error;
          } else {
            state.currentState = APPSTATE.ThankYou;
          }
        }
      )
      .addCase(withdraw.rejected, (state, action) => {
        state.status = RequestStatus.Failed;
        // state.error = action.error.message;
        state.error =
          action?.error?.message +
          " " +
          (action?.payload ? JSON.stringify(action?.payload) : "");
        state.previousState = state.currentState;
        state.currentState = APPSTATE.Error;
      })
      .addCase(accept.pending, (state, action) => {
        state.error = "";
        state.previousState = state.currentState;
        state.currentState = APPSTATE.WaitingResult;
        state.status = RequestStatus.Loading;
      })
      .addCase(
        accept.fulfilled,
        (state, action: PayloadAction<SetOTPResult>) => {
          state.status = RequestStatus.Succeeded;
          state.depositAmount.amount = 0;
          state.depositAmount.currency = "";
          state.depositAmount.lastPolledCommand = null;
          state.currentState = APPSTATE.AddMoney;
          // state.withrawalResult = action.payload;

          // if (action.payload && action.payload.ErrorCode) {
          //   state.error =
          //     "Result code: " +
          //     action.payload.ResultCode +
          //     ": " +
          //     action.payload.ErrorCode;
          //   state.currentState = APPSTATE.Error;
          // } else {
          //   state.currentState = APPSTATE.ThankYou;
          // }
        }
      )
      .addCase(accept.rejected, (state, action) => {
        state.status = RequestStatus.Failed;
        state.error =
          action?.error?.message +
          " " +
          (action?.payload ? JSON.stringify(action?.payload) : "");
        state.previousState = state.currentState;
        state.currentState = APPSTATE.ErrorCashin;
      })
      .addCase(cancel.pending, (state, action) => {
        state.error = "";
        state.previousState = state.currentState;
        state.currentState = APPSTATE.WaitingResult;
        state.status = RequestStatus.Loading;
      })
      .addCase(
        cancel.fulfilled,
        (state, action: PayloadAction<SetOTPResult>) => {
          state.status = RequestStatus.Succeeded;
          state.currentState = APPSTATE.Home;
          // state.withrawalResult = action.payload;

          // if (action.payload && action.payload.ErrorCode) {
          //   state.error =
          //     "Result code: " +
          //     action.payload.ResultCode +
          //     ": " +
          //     action.payload.ErrorCode;
          //   state.currentState = APPSTATE.Error;
          // } else {
          //   state.currentState = APPSTATE.ThankYou;
          // }
        }
      )
      .addCase(cancel.rejected, (state, action) => {
        state.status = RequestStatus.Failed;
        state.error =
          action?.error?.message +
          " " +
          (action?.payload ? JSON.stringify(action?.payload) : "");
        state.previousState = state.currentState;
        state.currentState = APPSTATE.Error;
      })
      .addCase(scanCryptoWallet.pending, (state, action) => {
        state.currentState = APPSTATE.ShowCryptoWalletQRCode;
        state.error = "";
        state.status = RequestStatus.Loading;
      })
      .addCase(scanCryptoWallet.fulfilled, (state, action) => {
        state.status = RequestStatus.Succeeded;
        state.previousState = state.currentState;
        state.currentState = APPSTATE.ThankYou;
      })
      .addCase(scanCryptoWallet.rejected, (state, action) => {
        state.status = RequestStatus.Failed;
        // state.error = action.error.message;
        state.error =
          action?.error?.message +
          " " +
          (action?.payload ? JSON.stringify(action?.payload) : "");
        state.previousState = state.currentState;
        state.currentState = APPSTATE.Error;
      })
      .addCase(hb_incustomerservice.pending, (state, action) => {
        // state.otpResult = null;
        state.error = "";
        state.status = RequestStatus.Loading;
      })
      .addCase(hb_incustomerservice.fulfilled, (state, action) => {
        state.status = RequestStatus.Succeeded;
        // state.otpResult = action.payload;

        state.previousState = state.currentState;
        state.currentState = APPSTATE.EnterOTP;
      })
      .addCase(hb_incustomerservice.rejected, (state, action) => {
        state.status = RequestStatus.Failed;
        // state.error = action.error.message;
        state.error =
          action?.error?.message +
          " " +
          (action?.payload ? JSON.stringify(action?.payload) : "");
        state.previousState = state.currentState;
        state.currentState = APPSTATE.Error;
      })
      .addCase(hb_withdraw.pending, (state, action) => {
        state.error = "";
        state.status = RequestStatus.Loading;
      })
      .addCase(
        hb_withdraw.fulfilled,
        (state, action: PayloadAction<SetOTPResult>) => {
          state.status = RequestStatus.Succeeded;
          state.withrawalResult = action.payload; // state.posts.concat(action.payload);

          if (action.payload && action.payload.ErrorCode) {
            state.error =
              "Result code: " +
              action.payload.ResultCode +
              ": " +
              action.payload.ErrorCode;
            state.currentState = APPSTATE.Error;
          } else {
            state.currentState = APPSTATE.ThankYou;
          }
        }
      )
      .addCase(hb_withdraw.rejected, (state, action) => {
        state.status = RequestStatus.Failed;
        // state.error = action.error.message;
        state.error =
          action?.error?.message +
          " " +
          (action?.payload ? JSON.stringify(action?.payload) : "");
      })
      .addCase(fetchATMStatus.pending, (state, action) => {
        state.error = "";
        state.atmStatus = null;
        state.status = RequestStatus.Loading;
      })
      .addCase(fetchATMStatus.fulfilled, (state, action) => {
        state.status = RequestStatus.Succeeded;
        state.atmStatus = action.payload;
      })
      .addCase(fetchATMStatus.rejected, (state, action) => {
        state.status = RequestStatus.Failed;
        // state.error = action.error.message;
        state.error =
          action?.error?.message +
          " " +
          (action?.payload ? JSON.stringify(action?.payload) : "");
        state.previousState = state.currentState;
        state.currentState = APPSTATE.Error;
      })
      .addCase(fetchTransaction.pending, (state, action) => {
        // state.error = "";
        // state.transactionStatus = null;
        // state.status = RequestStatus.Loading;
      })
      .addCase(fetchTransaction.fulfilled, (state, action) => {
        if (action.payload?.requestJson) {
          const deposited = parseCashinAccepedAmount(
            action.payload?.requestJson
          );
          // state.status = RequestStatus.Succeeded;
          state.depositAmount.amount = deposited?.Amount;
          state.depositAmount.currency = deposited?.Currency;
          state.depositAmount.lastPolledCommand = deposited?.LastPolledCommand;
        }
        state.transactionStatus = action.payload?.status;
      })
      .addCase(fetchTransaction.rejected, (state, action) => {
        // state.status = RequestStatus.Failed;
        state.error =
          action?.error?.message +
          " " +
          (action?.payload ? JSON.stringify(action?.payload) : "");
        state.previousState = state.currentState;
        state.currentState = APPSTATE.Error;
      })
      .addCase(sendBankApprove.pending, (state, action) => {
        // state.error = "";
        // state.transactionStatus = null;
        // state.status = RequestStatus.Loading;
      })
      .addCase(sendBankApprove.fulfilled, (state, action) => {
        // state.status = RequestStatus.Succeeded;
        // state.transactionStatus = action.payload;
      })
      .addCase(sendBankApprove.rejected, (state, action) => {
        // state.status = RequestStatus.Failed;
        state.error =
          action?.error?.message +
          " " +
          (action?.payload ? JSON.stringify(action?.payload) : "");
        // state.previousState = state.currentState;
        // state.currentState = APPSTATE.Error;
      })
      .addCase(sendBankRollback.pending, (state, action) => {
        // state.error = "";
        // state.transactionStatus = null;
        // state.status = RequestStatus.Loading;
      })
      .addCase(sendBankRollback.fulfilled, (state, action) => {
        // state.status = RequestStatus.Succeeded;
        // state.transactionStatus = action.payload;
      })
      .addCase(sendBankRollback.rejected, (state, action) => {
        // state.status = RequestStatus.Failed;
        state.error =
          action?.error?.message +
          " " +
          (action?.payload ? JSON.stringify(action?.payload) : "");
        // state.previousState = state.currentState;
        // state.currentState = APPSTATE.Error;
      });
  },
});

export const {
  sendSetAccount,
  clearErrors,
  changeCurrentState,
  setTestAtmEncryptKey,
  setTestAtmId,
  setUILanguage,
  setDepositAmount,
} = appSlice.actions;
export const selectRequestStatus = (state: RootState) => state.app.status;
export const selectPreviousState = (state: RootState) =>
  state.app.previousState;
export const selectCurrentState = (state: RootState) => state.app.currentState;
export const selectCurrentAccount = (state: RootState) =>
  state.app.currentAccount;
export const selectAccounts = (state: RootState) => state.app.accounts;

export default appSlice.reducer;
