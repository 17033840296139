import * as CryptoJS from "crypto-js";
import { initializationVector } from "../constants";

export class AesCrypter {
  public static encrypt(key: any, value: string) {
    key = CryptoJS.enc.Hex.parse(key);
    let ciphertext = CryptoJS.AES.encrypt(value, key, {
      iv: CryptoJS.enc.Hex.parse(initializationVector),
    }).toString();
    return ciphertext;
  }

  public static decrypt(key: any, value: string) {
    key = CryptoJS.enc.Hex.parse(key);
    let decryptedData = CryptoJS.AES.decrypt(value, key, {
      iv: CryptoJS.enc.Hex.parse(initializationVector),
    });

    const text = decryptedData.toString(CryptoJS.enc.Utf8);
    return text;
  }
}
