export enum DebugMode {
  Off = "off",
  On = "on",
}

export enum RadioValue {
  Off = "off",
  On = "on",
}

export enum LocalStorageItem {
  DebugMode = "debugMode",
  HbFlow = "hbFlow",
}

export enum CashOperation {
  CashIn = "CASH_IN",
  CashOut = "CASH_OUT",
}
