import axios, { AxiosInstance } from "axios";
import { ATM_URL, QRPAY_BANK_URL, QRPAY_SIMULATOR_URL } from "../constants";

class RequestService {
  private service: AxiosInstance;

  constructor(baseURL: string | undefined = QRPAY_SIMULATOR_URL) {
    const service = axios.create({
      baseURL,
    });

    service.interceptors.request.use(function (config) {
      config.headers["Content-Type"] = "application/json";
      config.headers.Accept = "application/json";

      return config;
    });

    this.service = service;
  }

  public getService() {
    return this.service;
  }
}

const bankSimulatorInstance = new RequestService(
  QRPAY_SIMULATOR_URL
).getService();
const bankInstance = new RequestService(QRPAY_BANK_URL).getService();
const atmDirectApiInstance = new RequestService(ATM_URL).getService();

export { bankSimulatorInstance, bankInstance, atmDirectApiInstance };
