export enum APPSTATE {
  Home = "HOME",
  ScanCode = "SCAN_CODE",
  EnterOTP = "ENTER_OTP",
  EnterOTPTimeout = "ENTER_OTP_TIMEOUT",
  SelectAmount = "SELECT_AMOUNT",
  AddMoney = "ADD_MONEY",
  Error = "ERROR",
  ErrorCashin = "ERROR_CASHIN",
  ThankYou = "THANK_YOU",
  ChooseOperation = "CHOOSE_OPERATION",
  ShowCryptoWalletQRCode = "SHOW_CRYPTO_WALLET_QRCODE",
  WaitingResult = "WAITING_RESULT",
}
