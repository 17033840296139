import { useRef, useState } from "react";
import QrReader from "react-qr-reader";
import { unwrapResult } from "@reduxjs/toolkit";
import axios from "axios";
import { useEffect } from "react";
import {
  Container,
  Modal,
  Button,
  Row,
  Col,
  Form,
  Badge,
  Alert,
  ToggleButton,
  ButtonGroup,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles.scss";
import "../../otp.scss";
import {
  getDebugMode,
  isJsonString,
  syntaxHighlight,
  getHbFlow,
} from "../../utils/common";
import {
  Cassette,
  DecryptedQrCodeInfo,
  EncryptedQrCodeInfo,
  Settings,
} from "../../types/types";
import OtpInput from "react-otp-input";

import { useAppSelector, useAppDispatch } from "../../hooks";
import {
  changeCurrentState,
  selectPreviousState,
  selectRequestStatus,
  fetchATMKey,
  sendSetOTP,
  withdraw,
  accept,
  scanCryptoWallet,
  fetchStartTransaction,
  setTestAtmEncryptKey,
  hb_incustomerservice,
  clearErrors,
  selectAccounts,
  selectCurrentAccount,
  sendSetAccount,
  sendCommand,
  fetchATMStatus,
  setDepositAmount,
  fetchTransaction,
  sendBankApprove,
  sendBankRollback,
  cancel,
} from "../../appSlice";
import { APPSTATE } from "../../enums/appState";
import { CashOperation, DebugMode, RadioValue } from "../../enums/common";
import { AesCrypter } from "../../utils/aesCrypter";
import { qrCodeMockKey, getMockQrCode } from "../../mockData";
import { enterOTPTimeout } from "../../constants";
import CountDown from "../../components/CountDown";
import qrcode from "../../assets/qrcode.png";
import bs2Logo from "../../assets/bs2-logo.png";
import Loader from "../../components/Loader";
import { Account } from "../../types/apiTypes";
import { TransactionStatus, TransactionType } from "../../enums/transactions";
import { ATMCommand } from "../../enums/atmCommands";
import bankAPI from "../../apis/bankAPI";
import { useTranslation } from "react-i18next";

const HomePage = () => {
  const { t } = useTranslation();
  const uiLanguage = useAppSelector((state) => state.app.uiLanguage);
  const [qrCodeEncryptedInfo, setQrCodeEncryptedInfo] =
    useState<EncryptedQrCodeInfo | null>(null);
  const [qrCodeDecryptedInfo, setQrCodeDecryptedInfo] =
    useState<DecryptedQrCodeInfo | null>(null);
  const [showDialog, setDiaglog] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [otp, setOtp] = useState<string>("");
  const [selectedAmount, setSelectedAmount] = useState<Cassette | null>(null);
  const [cashOperation, setCashOperation] = useState<CashOperation | null>(
    null
  );
  const [selected, setSelected] = useState<"user" | "environment" | undefined>(
    "environment"
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  // const [depositAmount, setDepositAmount] = useState<number>(0);
  const [depositDenominations, setDepositDenominations] = useState<number[]>([
    5, 10, 20, 50,
  ]);
  const currentState = useAppSelector((state) => state.app.currentState);
  const atmStatus = useAppSelector((state) => state.app.atmStatus);
  const transactionStatus = useAppSelector(
    (state) => state.app.transactionStatus
  );
  const depositAmount = useAppSelector((state) => state.app.depositAmount);
  const previousState = useAppSelector(selectPreviousState);
  const requestStatus = useAppSelector(selectRequestStatus);
  const accounts = useAppSelector(selectAccounts);
  const currentAccount = useAppSelector(selectCurrentAccount);
  const error = useAppSelector((state) => state.app.error);
  const atmCryptKey = useAppSelector((state) => state.app.atmEncryptKey);
  const transaction = useAppSelector((state) => state.app.transactionInfo);
  const otpResult = useAppSelector((state) => state.app.otpResult);
  const withdrawalResult = useAppSelector((state) => state.app.withrawalResult);
  const testAtmId = useAppSelector((state) => state.app.testAtmId);
  const dispatch = useAppDispatch();
  const debugMode = getDebugMode();
  const timer = useRef<number | null>(null);

  useEffect(() => {
    if (qrCodeEncryptedInfo && atmCryptKey) {
      try {
        const decrypted = AesCrypter.decrypt(
          atmCryptKey,
          qrCodeEncryptedInfo.Data
        );
        const decryptedQrCode: DecryptedQrCodeInfo = JSON.parse(decrypted);
        setQrCodeDecryptedInfo(decryptedQrCode);
      } catch (error) {
        setQrCodeDecryptedInfo(null);
      }
    } else {
      setQrCodeDecryptedInfo(null);
    }
  }, [qrCodeEncryptedInfo, atmCryptKey]);

  useEffect(() => {
    if (currentState === APPSTATE.EnterOTP) {
      if (timer.current !== null) return;
      timer.current = window.setTimeout(() => {
        dispatch(changeCurrentState(APPSTATE.EnterOTPTimeout));
        if (timer.current !== null) {
          clearTimeout(timer.current);
        }
      }, enterOTPTimeout);

      return () => {
        if (timer.current !== null) {
          window.clearTimeout(timer.current);
        }
      };
    } else {
      if (timer.current !== null) {
        window.clearTimeout(timer.current);
      }
    }
  }, [currentState]);

  useEffect(() => {
    let fetchTransactionId = setInterval(() => {
      if (cashOperation === CashOperation.CashIn && transaction) {
        dispatch(
          fetchTransaction({
            atmId: transaction.atmId,
            transactionId: transaction.transactionId,
          })
        );
      }
    }, 2000);

    return () => {
      clearInterval(fetchTransactionId);
    };
  }, [cashOperation, transaction]);

  useEffect(() => {
    if (
      cashOperation === CashOperation.CashIn &&
      transactionStatus === TransactionStatus.Success
    ) {
      dispatch(changeCurrentState(APPSTATE.ThankYou));
    }
  }, [transactionStatus]);

  const handleScan = async (scanData: any) => {
    setSelectedAmount(null);
    console.log(`loaded data data`, scanData);
    if (scanData && scanData !== "" && !showDialog && !processing) {
      try {
        const parsedScanData: EncryptedQrCodeInfo = isJsonString(scanData)
          ? JSON.parse(scanData)
          : scanData;
        if (parsedScanData) {
          console.log(`loaded >>>`, parsedScanData);
          setQrCodeEncryptedInfo(parsedScanData);

          if (parsedScanData) {
            dispatch(fetchATMKey(parsedScanData.AtmID));
          }

          if (debugMode === DebugMode.On) {
            setDiaglog(true);
          } else {
            dispatch(changeCurrentState(APPSTATE.ChooseOperation));
          }
          // setPrecScan(scanData);
          // await fetchData({ qr: scanData });
        }
      } catch (error: any) {
        setErrorMessage(error.message);
      }
    }
  };

  const handleError = (err: any) => {
    console.error(err);
  };

  const handleClose = () => {
    // setCode(null);
    setErrorMessage(null);
    setDiaglog(false);
    setProcessing(false);
  };

  const handleSend = () => {
    setDiaglog(false);
    dispatch(changeCurrentState(APPSTATE.ChooseOperation));
  };

  const PrettyPrintJson = ({
    data,
    className,
  }: {
    data: any;
    className: string;
  }) => (
    <div className={className}>
      <pre
        dangerouslySetInnerHTML={{
          __html: syntaxHighlight(JSON.stringify(data, undefined, 4)),
        }}
      ></pre>
    </div>
  );

  const handleChangeOtp = (otp: any) => {
    console.log("saras otp", otp);
    setOtp(otp);
  };

  const handleSetOperation = (cashOperation: CashOperation) => {
    if (qrCodeEncryptedInfo) {
      switch (cashOperation) {
        case CashOperation.CashIn:
          setCashOperation(CashOperation.CashIn);
          if (debugMode === DebugMode.On) {
            startTestTransaction(TransactionType.CashIn);
          } else {
            startTransaction(
              qrCodeEncryptedInfo?.AtmID,
              TransactionType.CashIn
            );
          }
          break;
        case CashOperation.CashOut:
          setCashOperation(CashOperation.CashOut);
          if (debugMode === DebugMode.On) {
            startTestTransaction(TransactionType.Cashout);
          } else {
            startTransaction(
              qrCodeEncryptedInfo?.AtmID,
              TransactionType.Cashout
            );
          }
          dispatch(changeCurrentState(APPSTATE.SelectAmount));
          break;
        default:
          break;
      }
    }
  };

  const handleConfirmOtp = async () => {
    switch (cashOperation) {
      case CashOperation.CashIn:
        if (otpResult) {
          dispatch(
            accept({
              atmId: qrCodeEncryptedInfo?.AtmID,
              userAccount: currentAccount?.name,
              otpCode: Number(otp),
              transactionId: otpResult?.TransactionID,
              admissionDetails: [
                {
                  Currency: "USD",
                  Denominations: [5, 10, 20, 50, 100],
                  Limit: 1000000,
                },
                {
                  Currency: "EUR",
                  Denominations: [5, 10, 20, 50, 100],
                  Limit: 1000000,
                },
                {
                  Currency: "KZT",
                  Denominations: [1000, 2000, 5000, 10000],
                  Limit: 1000000,
                },
                {
                  Currency: "UZS",
                  Denominations: [1000, 10000, 50000],
                  Limit: 1000000,
                },
              ],
            })
          );
        }
        break;
      case CashOperation.CashOut:
        if (otpResult && selectedAmount) {
          dispatch(
            withdraw({
              atmId: qrCodeEncryptedInfo?.AtmID,
              amount: selectedAmount.denomination, // 50,
              currency: selectedAmount.currency, // 'BEE',
              otpCode: Number(otp),
              transactionId: otpResult?.TransactionID,
              account: currentAccount,
            })
          );
        }
        break;
      default:
        break;
    }
  };

  const handleCancelOtp = async () => {
    dispatch(
      cancel({
        atmId: qrCodeEncryptedInfo?.AtmID,
        transactionId: otpResult?.TransactionID,
      })
    );
  };

  const handleClearErrors = async () => {
    dispatch(clearErrors());
  };

  const startTransaction = (
    atmId: string,
    transactionTypeId: TransactionType
  ) => {
    dispatch(
      fetchStartTransaction({
        atmId: atmId,
        transactionTypeId: transactionTypeId,
      })
    )
      .then(unwrapResult)
      .then((result) => {
        dispatch(fetchATMKey(atmId))
          .then(unwrapResult)
          .then((keyResult) => {
            if (transactionTypeId === TransactionType.CashIn) {
              handleSetOtp(
                atmId,
                result.transactionId,
                result.transactionDate,
                result.otpKey
              );
            }

            if (getHbFlow() === RadioValue.On) {
              handleSetIncustomerservice(atmId, result.stan);
            }
          });
      });

    // const doStartTransaction = () => async (dispatch: any) => {
    //   const result = await dispatch(
    //     fetchStartTransaction({ atmId, transactionTypeId })
    //   );

    //   if (transactionTypeId === TransactionType.CashIn) {
    //     handleSetOtp(
    //       atmId,
    //       result.transactionId,
    //       result.transactionDate,
    //       result.otpKey
    //     );
    //   }

    //   dispatch(fetchATMKey(atmId));
    //   if (getHbFlow() === RadioValue.On) {
    //     handleSetIncustomerservice(atmId, result.payload.stan);
    //   }
    // };

    // dispatch(doStartTransaction());
  };

  const startTestTransaction = (transactionTypeId: TransactionType) => {
    dispatch(
      fetchStartTransaction({
        atmId: testAtmId,
        transactionTypeId: transactionTypeId,
      })
    )
      .then(unwrapResult)
      .then((result) => {
        console.log("saras transaction otp ", transaction, result);
        if (transactionTypeId === TransactionType.CashIn) {
          handleSetOtp(
            testAtmId,
            result.transactionId,
            result.transactionDate,
            result.otpKey
          );
        }

        dispatch(setTestAtmEncryptKey(qrCodeMockKey));
        if (getHbFlow() === RadioValue.On) {
          handleSetIncustomerservice(testAtmId, result.stan);
        }
      });
  };

  const handleGotoState = (e: any) => {
    console.log("saras changeCurrentState", e.target.value);
    dispatch(changeCurrentState(e.target.value));
  };

  const handleSetOtp = (
    atmId: string,
    transactionId: string,
    transactionDate: string,
    otpKey: number
  ) => {
    console.log("saras handleSetOtp ", transaction);
    setOtp("");
    dispatch(
      sendSetOTP({
        atmId: atmId,
        transactionId: transactionId,
        transactionDateTime: transactionDate,
        otpCode: otpKey,
        account: currentAccount,
        language: uiLanguage.TerminalLanguageName,
      })
    );
  };

  const handleSetOtpCashout = (atmId: string, cassette: Cassette) => {
    console.log("saras handleSetOtpCashout", cassette);
    if (transaction) {
      setSelectedAmount(cassette);
      handleSetOtp(
        atmId,
        transaction?.transactionId,
        transaction?.transactionDate,
        transaction?.otpKey
      );
    }
  };

  const handleSendCommand = (atmId: string, command: ATMCommand) => {
    console.log(
      "saras handleSendCommand",
      atmId,
      otpResult,
      command,
      transaction
    );
    dispatch(
      sendCommand({
        atmId: atmId,
        transactionId: otpResult?.TransactionID,
        command: command,
      })
    )
      .then(unwrapResult)
      .then((result) => {
        // if (command === ATMCommand.Validate) {
        //   dispatch(
        //     fetchTransaction({
        //       atmId: atmId,
        //       transactionId: transaction?.transactionId,
        //     })
        //   );
        // }
        if (command === ATMCommand.Cancel) {
          setCashOperation(null);
          dispatch(changeCurrentState(APPSTATE.Home));
        }
      });
  };

  const handleSetAccount = (account: Account) => {
    console.log("saras handleSetAccount", account);
    dispatch(sendSetAccount(account));
  };

  const handleSetIncustomerservice = (atmId: string, stan: number) => {
    console.log("saras handleSetIncustomerservice", atmId);
    // setSelectedAmount(cassette);
    // setOtp("");
    dispatch(
      hb_incustomerservice({
        atmId: atmId,
        stan: stan,
      })
    );
    // dispatch(changeCurrentState(APPSTATE.EnterOTP));
  };

  const handleSimulateScan = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    // startTestTransaction(TransactionType.Cashout);
    dispatch(setTestAtmEncryptKey(qrCodeMockKey));
    const encryptedMock = getMockQrCode(testAtmId);
    setQrCodeEncryptedInfo(encryptedMock);
    dispatch(changeCurrentState(APPSTATE.ChooseOperation));
  };

  const handleGetKeyTest = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    dispatch(fetchATMKey(testAtmId));
  };

  const handleSetOTPTest = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setOtp("");
    dispatch(
      sendSetOTP({
        atmId: testAtmId,
        name: currentAccount?.name,
        account: currentAccount,
        language: uiLanguage.TerminalLanguageName,
      })
    );
  };

  const handleCryptoWalletScanTest = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    dispatch(
      scanCryptoWallet({
        atmId: testAtmId,
        account: currentAccount,
        language: uiLanguage.TerminalLanguageName,
      })
    );
  };

  const handleWithdrawTest = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    if (otpResult && selectedAmount) {
      dispatch(
        withdraw({
          atmId: testAtmId,
          amount: selectedAmount.denomination, // 50,
          currency: selectedAmount.currency, // 'BEE',
          otpCode: Number(otp),
          transactionId: otpResult?.TransactionID,
          account: currentAccount,
        })
      );
    }
  };

  // const handleWithdrawResult = (
  //   event: React.MouseEvent<HTMLButtonElement>
  // ): void => {};

  return (
    <Container>
      {/* <Row className="">
        {" "}
        <Button
          variant="primary"
          size="lg"
          onClick={() => {
            handleScan(
              `{"AtmID":"ATM_D_08.05","Data":"ONd0qW3WlLd9Rq8+RO+G6AiU7WwWk356NB+7PYl3arBVSYxzpFbuRPZrRiiOJq89CFyN84N65QuNx00mM5ruckiN6YebyWDXPxPE95lTiATsEoK2jg0170rpyr+aWT8jkVpNEQk74/0yWExLkoijrgaQulKkYxdHk9p0T1lFlq2M4r8xDDHYhkuqg53MDjZYvQxXeDYlmAALU+6X4/cHnurDrB4qN5/uyG+Im90dTAZSaBc/sH9dsZrCIzhx8mbRLmjJvsw+6UmC/PSGg47ioxWx0Ra068EHKGpPlIc1lmDGL1DJp2Njct/bbLsGhCKh4CWsXGkZic/2sNTscvOFOzDlZB5ZROT7tf/UxM/lH5fqm3zfj+hHLUV56KVZqy5uHz7W4D0JP9MDfNaagOa5+4Zcxh6XzeoX+5UGwpnoyRJTJ6fyJWy4y9oAKIg+hM9/Lb5FnFMy+HdjKz3GSaFvz+ZsxyAoGJQ7KlzZQnriC0lDiq1oiIEKEUcUjbe56DOwtoi4JJgC8vQUnoAs409HfYz6Zlet7fzgDH3DBdTRTKz0no6ONeH5eEI8YqkJRKCsnvKnCa+Y2TNAqLCS+ba1b9w0TpdNw0jz8oyvPii1dDtMl8Ropo5nvKcXJt/hxFTMr+U73CVQcXZxPluf0oebUg=="}`
            );
          }}
        >
          Handle scan
        </Button>
      </Row> */}
      {currentAccount && (
        <Row className="mt-4">
          <Col align="center">
            <p>
              {t("SelectedAccount")}:{" "}
              <Badge bg="secondary">
                {currentAccount.name} {cashOperation}
              </Badge>
            </p>
          </Col>
        </Row>
      )}
      {currentState === APPSTATE.Home && (
        <>
          <Row>
            {accounts && (
              <>
                <p className="h4 pt-2 bold text-center">{t("SelectAccount")}</p>
                <div className="mt-2 mb-4 d-grid gap-2">
                  {accounts.map((account: Account, index: number) => {
                    return (
                      <Button
                        key={`account_${index}`}
                        variant="success"
                        size="lg"
                        onClick={() => handleSetAccount(account)}
                      >
                        {account.name}
                      </Button>
                    );
                  })}
                </div>
              </>
            )}
          </Row>
          <Row>
            <span className="text-center">
              <img
                src={bs2Logo}
                style={{ width: "50%", opacity: 0.2 }}
                alt="BS/2"
              />
            </span>
          </Row>
        </>
      )}
      {error && (
        <Row className="mt-4">
          <Col>
            <Alert variant="danger" className="text-break">
              {error}
              <span
                aria-hidden="true"
                onClick={handleClearErrors}
                role="button"
                className="float-end"
              >
                &times;
              </span>
            </Alert>
          </Col>
        </Row>
      )}
      {currentState === APPSTATE.EnterOTP && (
        <>
          <Row className="justify-content-center">
            <Col className="text-center col-xl-3 col-lg-3 col-md-4 col-sm-6 col-8">
              <p className="h4 pt-4 bold">{t("EnterOTPfromATM")}</p>
              <CountDown seconds={enterOTPTimeout / 1000} />
              {debugMode === DebugMode.On &&
                transaction &&
                cashOperation === CashOperation.CashIn && (
                  <Badge bg="danger">Cashin OTP: {transaction.otpKey}</Badge>
                )}
              <div className="otpElements mt-4 mb-4">
                <div className="otp">
                  <OtpInput
                    shouldAutoFocus
                    value={otp}
                    onChange={handleChangeOtp}
                    inputStyle="inputStyle"
                    numInputs={4}
                    separator={<span></span>}
                    isInputNum
                  />
                </div>
                {/* <p className="pt-4">Didn't receive the code?</p>
              <p className="resend">Resend</p> */}
              </div>
              {/* <pre>
                {JSON.stringify(
                  {
                    atmId: atmId,
                    amount: selectedAmount?.denomination, // 50,
                    currency: selectedAmount?.currency, // 'BEE',
                    otpCode: otp,
                    transactionId: otpResult?.TransactionID,
                  },
                  null,
                  2
                )}
              </pre> */}
              <button
                type="submit"
                className="btn btn-primary me-2 mt-4 mb-4"
                onClick={handleConfirmOtp}
              >
                {t("Confirm")}
              </button>
              <button
                type="submit"
                className="btn btn-danger ms-2 mt-4 mb-4"
                onClick={handleCancelOtp}
              >
                {t("Cancel")}
              </button>
            </Col>
          </Row>
          <Row className="text-center">
            {errorMessage && <p className="pt-2 text-danger">{errorMessage}</p>}
          </Row>
        </>
      )}
      {currentState === APPSTATE.EnterOTPTimeout && (
        <Row className="justify-content-center mt-4">
          <Col className="text-center">
            <Alert variant="warning">{t("TimeoutTryAgain")}</Alert>
            <button
              type="submit"
              className="btn btn-primary mt-4 mb-4"
              onClick={() => {
                dispatch(changeCurrentState(APPSTATE.Home));
              }}
            >
              {t("Ok")}
            </button>
          </Col>
        </Row>
      )}
      {currentState === APPSTATE.ShowCryptoWalletQRCode && (
        <Row className="justify-content-center mt-4">
          <Col className="text-center">
            <Alert variant="warning">Scan QR code!</Alert>
            <span>
              <img src={qrcode} />
            </span>
            <button
              type="submit"
              className="btn btn-primary mt-4 mb-4"
              onClick={() => {
                dispatch(changeCurrentState(APPSTATE.Home));
              }}
            >
              {t("Ok")}
            </button>
          </Col>
        </Row>
      )}
      {currentState === APPSTATE.ThankYou && (
        <Row className="justify-content-center mt-4">
          <Col className="text-center">
            <Alert variant="success">{t("ThankYouChoosingUs")}</Alert>
            <button
              type="submit"
              className="btn btn-primary mt-4 mb-4"
              onClick={() => {
                dispatch(changeCurrentState(APPSTATE.Home));
              }}
            >
              {t("Ok")}
            </button>
          </Col>
        </Row>
      )}
      {/* {currentState === APPSTATE.ScanCode && !showDialog && !processing && ( */}
      {currentState === APPSTATE.ScanCode && (
        <>
          <Row className="mt-4 mb-4 text-center">
            <Col>
              <select
                onChange={(e) =>
                  setSelected(
                    e.target.value as "user" | "environment" | undefined
                  )
                }
              >
                <option value={"environment"}>{t("BackCamera")}</option>
                <option value={"user"}>{t("FrontCamera")}</option>
              </select>
            </Col>
          </Row>
          <Row className="text-center">
            <QrReader
              className="qr-image-wrapper"
              facingMode={selected}
              // constraints={{
              //   facingMode: selected,
              // }}
              delay={500}
              onError={handleError}
              onScan={handleScan}
              // chooseDeviceId={()=>selected}
              // style={{ width: "75%" }}
            />
          </Row>
        </>
      )}
      {currentState === APPSTATE.ChooseOperation && (
        <Row>
          {qrCodeDecryptedInfo ? (
            <>
              <p className="h4 pt-4 bold text-center">{t("ChooseOperation")}</p>
              <div className="mt-2 mb-4 d-grid gap-2 text-center">
                <Button
                  variant="success"
                  size="lg"
                  onClick={() => handleSetOperation(CashOperation.CashOut)}
                >
                  {t("CashOut")}
                </Button>
                <Button
                  variant="success"
                  size="lg"
                  onClick={() => handleSetOperation(CashOperation.CashIn)}
                >
                  {t("CashIn")}
                </Button>
              </div>
            </>
          ) : (
            <>{!error && <Loader />}</>
          )}
        </Row>
      )}
      {currentState === APPSTATE.WaitingResult && (
        <Row>
          <Loader />
        </Row>
      )}
      {currentState === APPSTATE.SelectAmount && (
        <Row>
          {qrCodeDecryptedInfo && !error ? (
            <>
              {/* <p className="pt-2 text-center">
                <Badge bg="secondary">{cashOperation}</Badge>
              </p> */}
              <p className="h4 pt-2 bold text-center">
                {t("SelectWithdrawalAmount")}
              </p>
              <div className="mt-2 mb-4 d-grid gap-2">
                {qrCodeDecryptedInfo &&
                  qrCodeDecryptedInfo.Cass &&
                  qrCodeDecryptedInfo.Cass.map(
                    (cassette: Cassette, index: number) => {
                      return (
                        <Button
                          key={`cassette_${index}`}
                          variant="success"
                          size="lg"
                          onClick={() =>
                            handleSetOtpCashout(
                              qrCodeDecryptedInfo.AtmID,
                              cassette
                            )
                          }
                        >
                          {cassette.denomination} {cassette.currency}
                        </Button>
                      );
                    }
                  )}
              </div>
            </>
          ) : (
            <>{!error && <Loader />}</>
          )}
        </Row>
      )}
      {currentState === APPSTATE.AddMoney && (
        <Row>
          {transaction && (
            <>
              {/* <p className="pt-2 text-center">
                <Badge bg="secondary">{cashOperation}</Badge>
              </p> */}
              <p className="h4 pt-2 bold text-center">{t("InsertMoney")}</p>
              <p className="pt-2 bold text-center">{t("InsertedAmount")}</p>
              <p className="mb-4 bold text-center h1">
                <span className="border rounded p-1 ps-4 pe-4">
                  {depositAmount?.amount}{" "}
                  <small className="text-muted">
                    {depositAmount?.currency}
                  </small>
                </span>
              </p>
              {debugMode === DebugMode.On && (
                <div className="mt-2 mb-4 btn-toolbar justify-content-center">
                  {depositDenominations.map(
                    (denomination: number, index: number) => {
                      return (
                        <Button
                          key={`denomination_${index}`}
                          variant="white"
                          size="lg"
                          className="me-2 ms-2 btn-outline-dark"
                          onClick={() => {
                            dispatch(setDepositAmount(denomination));
                            const response = bankAPI.depositCommandResult(
                              transaction.atmId,
                              transaction.transactionId,
                              transaction.transactionDate,
                              depositAmount?.amount + denomination
                            );
                            console.log("depositCommandResult", response);
                          }}
                        >
                          {denomination}
                        </Button>
                      );
                    }
                  )}
                </div>
              )}
              {/* <div className="d-grid gap-2">
                <Button
                  variant="danger"
                  size="lg"
                  onClick={() => {
                    const response = bankAPI.depositCommandResult(
                      transaction.atmId,
                      transaction.transactionId,
                      transaction.transactionDate,
                      depositAmount
                    );
                    console.log("depositCommandResult", response);
                  }}
                >
                  SIMULATE ADD MONEY
                </Button>
              </div> */}
              <div className="mt-2 mb-4 d-grid gap-2">
                <Button
                  variant="success"
                  size="lg"
                  disabled={
                    depositAmount?.lastPolledCommand !== 0 ||
                    transactionStatus !== TransactionStatus.IntermediateResults
                  }
                  onClick={() =>
                    handleSendCommand(transaction.atmId, ATMCommand.Validate)
                  }
                >
                  {t("ValidateMoney")}
                </Button>
                <Button
                  variant="success"
                  size="lg"
                  disabled={
                    depositAmount?.amount <= 0 ||
                    transactionStatus !== TransactionStatus.IntermediateResults
                  }
                  onClick={() =>
                    handleSendCommand(transaction.atmId, ATMCommand.Confirm)
                  }
                >
                  {t("DepositMoney")}
                </Button>
                <Button
                  variant="danger"
                  size="lg"
                  onClick={() =>
                    handleSendCommand(transaction.atmId, ATMCommand.Cancel)
                  }
                  className="mt-4"
                >
                  {t("Cancel")}
                </Button>
              </div>
              <div className="mt-4 mb-4 d-grid gap-2">
                <Button
                  variant="success"
                  size="lg"
                  disabled={
                    transactionStatus !== TransactionStatus.DepositAcceptReady
                  }
                  onClick={() =>
                    dispatch(
                      sendBankApprove({
                        atmId: transaction.atmId,
                        transactionId: transaction.transactionId,
                        // transactionDateTime: transaction.transactionDate,
                        otpCode: otp,
                      })
                    )
                  }
                >
                  {t("BankApprove")}
                </Button>
                <Button
                  variant="danger"
                  size="lg"
                  disabled={
                    transactionStatus !== TransactionStatus.DepositAcceptReady
                  }
                  onClick={() =>
                    dispatch(
                      sendBankRollback({
                        atmId: transaction.atmId,
                        transactionId: transaction.transactionId,
                      })
                    )
                  }
                >
                  {t("BankRollback")}
                </Button>
                {debugMode === DebugMode.On && (
                  <Button
                    variant="dark"
                    size="lg"
                    onClick={() => dispatch(fetchATMStatus(transaction.atmId))}
                  >
                    GET ATM STATUS
                  </Button>
                )}
                {debugMode === DebugMode.On && atmStatus && (
                  <p>
                    <pre>{JSON.stringify(atmStatus, null, 2)}</pre>
                  </p>
                )}
                {/* <Button
                  variant="dark"
                  size="lg"
                  onClick={() =>
                    dispatch(
                      fetchTransaction({
                        atmId: transaction.atmId,
                        transactionId: transaction.transactionId,
                      })
                    )
                  }
                >
                  GET TRANSACTION STATUS INFO
                </Button> */}
                {debugMode === DebugMode.On && transactionStatus && (
                  <p>
                    {t("TransactionStatus")}:{" "}
                    <pre>{JSON.stringify(transactionStatus, null, 2)}</pre>
                  </p>
                )}
              </div>
            </>
          )}
        </Row>
      )}
      {currentState === APPSTATE.ErrorCashin && (
        <Row>
          {transaction && (
            <div className="mt-2 mb-4 d-grid gap-2">
              <Button
                variant="danger"
                size="lg"
                onClick={() =>
                  handleSendCommand(transaction?.atmId, ATMCommand.Cancel)
                }
              >
                Cancel last transaction on ATM
              </Button>
            </div>
          )}
        </Row>
      )}

      {debugMode === DebugMode.On && (
        <>
          <Row className="mt-5">
            <Col align="center">
              <p>
                Previous app state:{" "}
                <Badge bg="secondary">{previousState}</Badge>
              </p>
            </Col>
            <Col align="center">
              <p>
                Current app state: <Badge bg="success">{currentState}</Badge>
              </p>
            </Col>
            <Col align="center">
              <p>
                Last request status:{" "}
                <Badge bg="secondary">{requestStatus}</Badge>
              </p>
            </Col>
          </Row>

          <Row>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>Go to state:</Form.Label>
                <Form.Select onChange={handleGotoState}>
                  {(Object.keys(APPSTATE) as (keyof typeof APPSTATE)[]).map(
                    (key, index) => {
                      return <option value={APPSTATE[key]}>{key}</option>;
                    }
                  )}
                </Form.Select>
              </Form.Group>
            </Form>
          </Row>
          <Row>
            <div className="d-grid gap-2">
              <Button variant="primary" size="lg" onClick={handleSimulateScan}>
                Simulate scan
              </Button>
              <Button variant="primary" size="lg" onClick={handleGetKeyTest}>
                GetKey
              </Button>
              <Button variant="primary" size="lg" onClick={handleSetOTPTest}>
                SetOTP
              </Button>
              <Button variant="primary" size="lg" onClick={handleWithdrawTest}>
                Withdrawal
              </Button>
              <Button
                variant="primary"
                size="lg"
                onClick={handleCryptoWalletScanTest}
              >
                Scan crypto wallet
              </Button>
              {/* <Button
              variant="secondary"
              size="lg"
              onClick={handleWithdrawResult}
            >
              Withdrawal result
            </Button> */}
            </div>
          </Row>
          {transaction && (
            <Row className="mt-4">
              <div className="d-grid gap-2">
                Transaction info:
                <pre>{JSON.stringify(transaction, null, 2)}</pre>
              </div>
            </Row>
          )}
          {atmCryptKey && (
            <Row className="mt-4">
              <div className="d-grid gap-2">AtmCryptKey: {atmCryptKey}</div>
            </Row>
          )}
          {otpResult && (
            <Row className="mt-4">
              <div className="d-grid gap-2">
                OtpResult: <pre>{JSON.stringify(otpResult, null, 2)}</pre>
              </div>
            </Row>
          )}
          {withdrawalResult && (
            <Row className="mt-4">
              <div className="d-grid gap-2">
                WithdrawalResult:
                <pre>{JSON.stringify(withdrawalResult, null, 2)}</pre>
              </div>
            </Row>
          )}
        </>
      )}
      <Modal show={showDialog} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Scan results</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {qrCodeEncryptedInfo && (
            <PrettyPrintJson
              data={qrCodeEncryptedInfo}
              className="jsonPreview"
            />
          )}
          {qrCodeDecryptedInfo && atmCryptKey && (
            <PrettyPrintJson
              data={qrCodeDecryptedInfo}
              className="jsonPreview"
            />
          )}
          {atmCryptKey}
          {errorMessage && <p className="text-danger">{errorMessage}</p>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("Close")}
          </Button>
          <Button
            variant="primary"
            onClick={handleSend}
            disabled={!atmCryptKey}
          >
            Send data
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default HomePage;
