import {
  CashinResult,
  TransactionAmount,
  WithdrawalResult,
} from "../types/apiTypes";

export const parseCashoutTransaction = (
  result: WithdrawalResult
): TransactionAmount => {
  return {
    Amount:
      result !== undefined && result?.Amount !== undefined
        ? Number(result?.Amount)
        : 0,
    Currency:
      result !== undefined && result?.Amount !== undefined
        ? result?.Currency
        : "",
  };
};

export const parseCashinDepositedAmount = (
  result: CashinResult
): TransactionAmount => {
  let amount = 0;
  let currency = "";

  if (
    result !== undefined &&
    result?.Deposited !== undefined &&
    result?.Deposited !== null
  ) {
    result?.Deposited.forEach((e) => {
      amount += Number(e.Denomination) * Number(e.Count);
      currency += e.Currency;
    });
  }
  return {
    Amount: amount,
    Currency: currency,
    LastPolledCommand: result?.LastPolledCommand,
  };
};

export const parseCashinAccepedAmount = (
  result: CashinResult
): TransactionAmount => {
  let amount = 0;
  let currency = "";

  if (
    result !== undefined &&
    result?.Accepted !== undefined &&
    result?.Accepted !== null
  ) {
    result?.Accepted.forEach((e) => {
      amount += Number(e.Denomination) * Number(e.Count);
      currency += e.Currency;
    });
  }
  return {
    Amount: amount,
    Currency: currency,
    LastPolledCommand: result?.LastPolledCommand,
  };
};
