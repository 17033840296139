import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  Container,
  Modal,
  Button,
  Row,
  Col,
  Form,
  Badge,
  Alert,
  ToggleButton,
  ButtonGroup,
  Table,
} from "react-bootstrap";
import { ArrowClockwise } from "react-bootstrap-icons";
import {
  fetchTransactions,
  fetchGetWithdrawalResult,
  selectReloadingItemsIds,
} from "../../transactionsSlice";
import { BankTransaction } from "../../types/apiTypes";
import { useEffect } from "react";
import moment from "moment";
import { maskOtp } from "../../utils/common";
import { TransactionStatus, TransactionType } from "../../enums/transactions";
import {
  parseCashinDepositedAmount,
  parseCashoutTransaction,
} from "../../utils/transactions";
import { useTranslation } from "react-i18next";

const TransactionsPage = () => {
  const { t } = useTranslation();
  const error = useAppSelector((state) => state.transactions.error);
  const transactions = useAppSelector((state) => state.transactions.items);
  const reloadingItemsIds = useAppSelector(selectReloadingItemsIds);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchTransactions());
  }, []);

  const handleRefresh = () => {
    dispatch(fetchTransactions());
  };

  const handleGetWithdrawalResult = (atmId: string, transactionId: string) => {
    dispatch(fetchGetWithdrawalResult({ atmId, transactionId }));
  };

  return (
    <Container>
      {error && (
        <Row className="mt-4">
          <Alert className="text-break" variant="danger">
            {error}
          </Alert>
        </Row>
      )}
      <Row className="mt-4">
        <h3>
          {t("Transactions")}{" "}
          <Button
            className="btn"
            type="button"
            variant="outline-success"
            onClick={handleRefresh}
          >
            <ArrowClockwise />
          </Button>
        </h3>
      </Row>
      <Table responsive striped bordered hover variant="dark">
        <thead>
          <tr>
            <th>#</th>
            <th>Transaction Id</th>
            <th className="text-center">Terminal Id</th>
            <th className="text-center">{t("Status")}</th>
            <th className="text-center">Date</th>
            <th className="text-center">Amount</th>
            <th className="text-center">OTP</th>
            <th className="text-center">Cash operation</th>
            {/* <th>EndpointName</th> */}
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {transactions?.map((m: BankTransaction, index) => {
            // const isSuccess = m.responseJson.ResultCode === "0";
            const transactionAmount =
              m.transactionTypeId === TransactionType.CashIn
                ? parseCashinDepositedAmount(m.requestJson)
                : parseCashoutTransaction(m.responseJson);

            let status = "secondary";
            switch (m.status) {
              case TransactionStatus.Success:
              case TransactionStatus.Deposited:
                status = "success";
                break;
              case TransactionStatus.Pending:
              case TransactionStatus.DepositStarted:
                status = "secondary";
                break;
              case TransactionStatus.Timeout:
                status = "primary";
                break;
              case TransactionStatus.WithdrawalError:
              case TransactionStatus.WithdrawalResultError:
              case TransactionStatus.AtmFailure:
                status = "danger";
                break;
              default:
                status = "dark";
                break;
            }
            return (
              <tr>
                <td>{m.id}</td>
                <td>{m.transactionId}</td>
                <td className="text-center">{m.atmId}</td>
                <td className="text-center">
                  {reloadingItemsIds.includes(m.transactionId) ? (
                    <div
                      className="ms-1 spinner-grow spinner-grow-sm text-success"
                      role="status"
                    ></div>
                  ) : (
                    <Badge bg={status}>
                      {TransactionStatus[m.status] ?? m.status}
                    </Badge>
                  )}
                </td>
                <td className="text-center">
                  {moment(m.transactionDate).format("YYYY-MM-DD HH:mm:ss")}
                </td>
                <td className="text-center">
                  {transactionAmount.Amount > 0 ? transactionAmount.Amount : ""}
                  {transactionAmount.Currency}
                </td>
                <td className="text-center">{maskOtp(m.otpKey)}</td>
                <td className="text-center">
                  {TransactionType[m.transactionTypeId] ?? m.transactionTypeId}
                </td>
                {/* <td>{m.endpointName}</td> */}
                <td className="text-center">
                  {m.status !== TransactionStatus.Success &&
                    m.transactionTypeId !== TransactionType.CashIn && (
                      <Button
                        className="btn btn-sm"
                        type="button"
                        variant="outline-success"
                        onClick={() => {
                          handleGetWithdrawalResult(m.atmId, m.transactionId);
                        }}
                        title="Retry get status"
                        disabled={reloadingItemsIds.includes(m.transactionId)}
                      >
                        <ArrowClockwise />
                      </Button>
                    )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
};

export default TransactionsPage;
