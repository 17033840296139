import React, { useEffect } from "react";
import { Row, Alert, Col } from "react-bootstrap";
import { Clock } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

const CountDown = ({ seconds }: { seconds: number }) => {
  const { t } = useTranslation();
  const [counter, setCounter] = React.useState(seconds);

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  return (
    <Row className="justify-content-md-center">
      <Col className="col-md-auto">
        <Alert variant="warning">
          {t("Countdown")}: <strong>{counter}</strong>
        </Alert>
      </Col>
    </Row>
  );
};

export default CountDown;
