import axios from "axios";
import { bankInstance, bankSimulatorInstance } from "./apiInstance";
import moment from "moment";
import { ATMStatus } from "../types/apiTypes";
import { TransactionType } from "../enums/transactions";
import { instanceOfBadResponseResult } from "../utils/common";
import { ATMCommand } from "../enums/atmCommands";

class bankAPI {
  static async depositCommandResult(
    atmId: string,
    transactionId: string,
    transactionDate: string,
    amount: number
  ) {
    const body = {
      AtmID: atmId,
      TransactionID: transactionId,
      Command: "Validate",
      ResultDateTime: transactionDate, // "2023-03-23T09:44:01.937Z",
      Funds: {
        items: {
          Currency: "BEE",
          Denomination: 1,
          Quantity: 1,
          Amount: amount,
        },
      },
      Status: "string",
      HWErrorCode: "string",
    };
    const { data } = await bankInstance.post<ATMStatus>(
      `/api/deposit/command/result`,
      body
    );

    if (instanceOfBadResponseResult(data) && data.ResultCode !== "0") {
      throw new Error(JSON.stringify(data));
    }

    return data;
  }
}

export default bankAPI;
