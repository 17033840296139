import { Account } from "./types/apiTypes";
import { UILanguage } from "./types/types";

const ENV = window._env_;

export const QRPAY_SIMULATOR_URL =
  ENV?.REACT_APP_QRPAY_SIMULATOR_URL ||
  process?.env?.REACT_APP_QRPAY_SIMULATOR_URL;
export const QRPAY_BANK_URL = "https://qrpay.iq-dev.k8.bs2.lt/";
// export const QRPAY_SIMULATOR_URL = "https://localhost:7223/";
// export const QRPAY_BANK_URL = "https://localhost:7080/";
export const ATM_URL = "http://192.168.34.62:8080";
export const TestTerminalID = "ATMSIMTEST";
export const initializationVector = "B6DF5741536AD0A4A345B23D459C8A4F";

export const enterOTPTimeout = 30000;

export const DEFAULT_LANGUAGE = "ru";

export const UILanguages: UILanguage[] = [
  {
    Code: "RU",
    UICode: "RU",
    Name: "Russian",
    TerminalLanguageName: "Russian",
  },
  {
    Code: "EN",
    UICode: "GB",
    Name: "English",
    TerminalLanguageName: "English",
  },
  {
    Code: "UZ",
    UICode: "UZ",
    Name: "Uzbek",
    TerminalLanguageName: "Uzbek",
  },
  {
    Code: "KZ",
    UICode: "KZ",
    Name: "Kazakh",
    TerminalLanguageName: "Kazakh",
  },
];

export const bankAccounts: Account[] = [
  {
    accountId: 1,
    name: "Nurtay",
    balance: 100,
    phoneNumber: "1111111",
    ip: "11.11.11.11",
  },
  {
    accountId: 2,
    name: "Milda",
    balance: 100,
    phoneNumber: "22222222",
    ip: "22.22.22.22",
  },
  {
    accountId: 3,
    name: "Tatyana",
    balance: 100,
    phoneNumber: "333333333",
    ip: "33.33.33.33",
  },
];
