export enum TransactionType {
  Cashout = 1,
  CashIn = 2,
}

export enum TransactionStatus {
  Pending = 0,
  Success = 1,
  WithdrawalError = 2,
  WithdrawalResultError = 3,
  DepositStarted = 5,
  DepositValidated = 6,
  Deposited = 7,
  Cancelled = 8,
  Timeout = 9,
  AtmFailure = 10,
  UnknownStatus = 11,
  CriticalManual = 12,
  Retract = 13,
  Reject = 14,
  Empty = 15,
  Cancel = 16,
  CustomerCancel = 17,
  DepositAcceptReady = 18,
  IntermediateResults = 19,
}
